import splitting from 'splitting';

export function splitText(target) {
  const splittingElement = splitting({ target });
  const animINdelay = target.getAttribute('animin-delay') || '0';
  const splitElementChars = splittingElement[0].chars;

  splitElementChars.forEach((char) => {
    char.style.setProperty('--animin-delay', animINdelay);
  });
}