import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from 'render/views/pages/HomePage';
import WhoWeArePage from 'render/views/pages/WhoWeArePage';
import TeamPage from 'render/views/pages/TeamPage';
import ContactsPage from 'render/views/pages/ContactsPage';
import PageNotFound from 'render/views/pages/PageNotFound';
import TermsAndConditionsPage from 'render/views/pages/TermsAndConditionsPage';
import PrivacyPolicyPage from 'render/views/pages/PrivacyPolicyPage';

export const ROUTES = {
  HOME: '/',
  TEAM: '/team',
  CONTACTS: '/contacts',
  WHO_WE_ARE: '/who-we-are',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICTY: '/privacy-policy',
};

const RoutesFile = () => {

  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={<HomePage />}
        exact
      />
      <Route
        path={ROUTES.TEAM}
        element={<TeamPage />}
      />
      <Route
        path={ROUTES.WHO_WE_ARE}
        element={<WhoWeArePage />}
      />
      <Route
        path={ROUTES.CONTACTS}
        element={<ContactsPage />}
      />
      <Route
        path={ROUTES.TERMS_AND_CONDITIONS}
        element={<TermsAndConditionsPage />}
      />
      <Route
        path={ROUTES.PRIVACY_POLICTY}
        element={<PrivacyPolicyPage />}
      />
      <Route
        path="*"
        element={<PageNotFound />} />

    </Routes>

  )

};

export default RoutesFile;