import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from 'routes/routes';
import { useLocation } from 'react-router-dom';

import LogoGreen from 'render/assets/images/triggr-ventures-logo-green.svg';

function HeaderComponent() {
  const location = useLocation();
  const page = location.pathname;
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuButtonClick = event => {
    setIsOpen(current => !current);
  };

  return (
    <header id="main-header" className={isOpen ? "menu-is-open" : "menu-is-closed"}>

      <div className="container">
        <Link to={ROUTES.HOME}><img src={LogoGreen} alt="TriggrVentures Logo" width="auto" height="100%"/></Link>
        <button aria-label="Open Menu" className="btn btn-square btn-ghost border-primary" onClick={handleMenuButtonClick}><span></span><span></span></button>
      </div>
      <nav id="sidebar-menu" page={page}>
          {page === "/" ? <span className="menu-item active"><h2>home</h2></span> : <Link className="menu-item" to={ROUTES.HOME} onClick={handleMenuButtonClick}><h2>home</h2></Link>}
          {page === "/who-we-are" ? <span className="menu-item active"><h2>who we are</h2></span> : <Link className="menu-item" to={ROUTES.WHO_WE_ARE} onClick={handleMenuButtonClick}><h2>who we are</h2></Link>}
          {page === "/team" ? <span className="menu-item active"><h2>team</h2></span> : <Link className="menu-item" to={ROUTES.TEAM} onClick={handleMenuButtonClick}><h2>team</h2></Link>}
          {page === "/contacts" ? <span className="menu-item active"><h2>contacts</h2></span> : <Link className="menu-item" to={ROUTES.CONTACTS} onClick={handleMenuButtonClick}><h2>contacts</h2></Link>}
      </nav>
    </header>
  );
};

export default HeaderComponent;