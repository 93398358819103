import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BackgroundHome from 'render/assets/images/background-home.avif';
import BackgroundHomeJpg from 'render/assets/images/background-home.jpg';
import { ROUTES } from 'routes/routes';
import { splitText } from 'scripts/Utils';
import ogImage from 'render/assets/images/who_we_are_image.png';

function HomePage() {

  useEffect(() => {
    [home1, home2, home3, home4, home5].forEach((ref) => {
      splitText(ref.current);
    });
  }, []);

  const home1 = useRef(null);
  const home2 = useRef(null);
  const home3 = useRef(null);
  const home4 = useRef(null);
  const home5 = useRef(null);

  return (
    <>
      <Helmet>
        <title>TriggrVentures - Home</title>
        <meta name="description" content="Welcome to TriggrVentures, an operator-led venture builder that specializes in building and growing innovative businesses across various industries." />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <section>
        <div className="container">
          <div className="anim-wrapper" data-scroll data-scroll-call="initAnimate">
            <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" x="0" y="0" style={{ enableBackground: "new 0 0 58.5 87" }} version="1.1" viewBox="0 0 58.5 87">
              <path d="M37.2 87V65.6c4 8 12.2 13.1 21.1 13.2h.2V62.2h-.2c-9.8 0-18.6-6.1-22.1-15.3h-14C18.8 56.1 10 62.2.2 62.2H0v16.6h.2c9 0 17.2-5.1 21.1-13.2V87h15.9z" />
              <circle cx="29.2" cy="20.1" r="20" />
            </svg>
          </div>
          <div className="anim-wrapper" data-scroll data-scroll-speed="-2" data-scroll-call="initAnimate">
            <h1 animchild="true" animin-type="text-word-reveal" data-split="true" ref={home1}>Together, we engineer<br />the revolutions of the<br />future.</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="anim-wrapper" data-scroll data-scroll-speed="-1" data-scroll-repeat="false" data-scroll-offset="25%">
            <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={home2}>We are an operator-led venture builder that specialises in building and growing innovative businesses across various industries.</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
          <p animchild="true" animin-type="small-slide-toTOP" animin-delay="1">We work closely with entrepreneurs, startups, corporates and other business partners to build successful companies, invest in promising projects, and pass-on our knowledge and experience to people from all over the world, from all kinds of companies - big or small.</p>
          <p animchild="true" animin-type="small-slide-toTOP" animin-delay="3">With an extreme hands-on approach, we are committed to delivering outstanding results for our clients and partners. We believe that the key to success lies in building strong relationships, so we work closely with our clients to ensure that their vision becomes a reality.</p>
          <p animchild="true" animin-type="small-slide-toTOP" animin-delay="5">Our current geographic footprint spans in Europe and Africa, where we support and build initiatives that have the potential to create significant value, in a range of sectors, from entrepreneurship and intrapreneurship to entertainment, agriculture, education, logistics and mobility, housing, fintech, healthtech, software development, and venture capital.</p>
          <div animchild="true" animin-type="small-slide-toTOP" animin-delay="7">
            <div>
              <Link to={ROUTES.WHO_WE_ARE} className="btn btn-ghost border-primary">discover +</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="has-img-bg">
        <div className="container" data-scroll data-scroll-speed="4" data-scroll-repeat="false" data-scroll-offset="25%">
          <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={home3}>empowering your ideas and turning them into success stories</h2>
        </div>
        <picture>
          <source srcSet={BackgroundHome} type="image/avif"/>
          <img className="section-image" src={BackgroundHomeJpg} alt="Concrete background with dimly lit person in the middle, walking forwards"/>
        </picture>
      </section>
      <section>
        <div className="container">
          <div className="anim-wrapper" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
            <p animchild="true" animin-type="small-slide-toTOP" animin-delay="1">Our team of industry professionals is dedicated to helping you transform your ideas into reality.
              With our extensive experience in building and growing businesses, we offer a comprehensive range of resources tailored to your needs. Whether you're a startup, corporate, or an entrepreneur, we're here to provide the support necessary for your ideas to flourish and achieve their fullest potential</p>
          </div>
          <div className="anim-wrapper" data-scroll data-scroll-speed="-1" data-scroll-repeat="false" data-scroll-offset="25%">
            <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={home4}>Your ideas, our expertise: a winning combination for success</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
          <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={home5}><a href="/contacts" className="inline-link">Join us</a> to co-create tomorrow's game-changers, transforming businesses now and beyond.</h2>
        </div>
      </section>
    </>
  );
}

export default HomePage