import React, { useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from 'routes/routes';
import { splitText } from 'scripts/Utils';
import ogImage from 'render/assets/images/who_we_are_image.png';
import { Helmet } from 'react-helmet';

function PageNotFound() {

  useEffect(() => {
    [not_found].forEach((ref) => {
      splitText(ref.current);
    });
  }, []);

  const not_found = useRef(null);

  return (
    <>
      <Helmet>
        <title>TriggrVentures - Page Not Found</title>
        <meta name="description" content="The page you are looking for could not be found on TriggrVentures." />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <section>
        <div className="container" data-scroll data-scroll-call="initAnimate">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 40.1 58.5" }} viewBox="0 0 40.1 58.5">
              <path d="M0 37.2h21.4c-8 4-13.1 12.2-13.2 21.1v.2h16.6v-.2c0-9.8 6.1-18.6 15.3-22.1v-14C30.9 18.8 24.8 10 24.8.2V0H8.2v.2c0 9 5.1 17.2 13.2 21.1H0v15.9z" />
            </svg>
          </div>
          <div>
            <h1 animchild="true" animin-type="text-word-reveal" data-split="true" ref={not_found}>Page not found</h1>
            <p>It seems this page was moved or never existed.</p>
            <Link to={ROUTES.HOME} className="btn btn-ghost border-primary">Go back home</Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default PageNotFound