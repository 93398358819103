import React, { useRef, useEffect } from 'react';
import { splitText } from 'scripts/Utils';
import arrow_down from 'render/assets/icons/triggr-ventures-arrow.svg';
import backgroundImage from 'render/assets/images/triggr-ventures-team.avif';
import backgroundImageJpg from 'render/assets/images/triggr-ventures-team.jpg';
import ogImage from 'render/assets/images/who_we_are_image.png';
import { Helmet } from 'react-helmet';

function TeamPage() {
  useEffect(() => {
    [team1, team2, team3].forEach((ref) => {
      splitText(ref.current);
    });
  }, []);

  const team1 = useRef(null);
  const team2 = useRef(null);
  const team3 = useRef(null);

  return (
    <>
      <Helmet>
        <title>TriggrVentures - Team</title>
        <meta name="description" content="Meet the team at TriggrVentures." />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <section data-scroll data-scroll-call="initAnimate">
        <div className="container">
          <h3>Programmers</h3>
          <h3>branding</h3>
          <h3>financial analysts</h3>
          <h3>graphic design</h3>
          <h3>testing</h3>
          <h3>mockups</h3>
          <h3>market research</h3>
          <h3>animation</h3>
          <h3>advisory</h3>
          <h3>planning</h3>
          <h3>product management</h3>
          <h3>coders</h3>
          <h3>marketing</h3>
          <h3>tax consultants</h3>
          <h3>user experience (ux)</h3>
          <h3>framework</h3>
          <h3>performance evaluation</h3>
          <h3>wireframing</h3>
          <h3>swot analysis</h3>
          <h3>photography</h3>
          <h3>coaching</h3>
          <h3>tactics</h3>
          <h3>risk assessment</h3>
        </div>
        <button type="button" className="btn btn-ghost btn-square border-white-offset">
          <img src={arrow_down} alt="triggr arrow down" />
          {/* <img src={arrow_down} alt="logo green" style={{ height: 50, margin: 5 }} /> */}
        </button>
      </section>
      <section>
        <div className="container">
          <div className="anim-wrapper" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
            <p animchild="true" animin-type="small-slide-toTOP" animin-delay="1">
              At Triggr Ventures, our diverse and talented team is the cornerstone of our success.
            </p>
          </div>
          <div className="anim-wrapper" data-scroll data-scroll-speed="-1" data-scroll-repeat="false" data-scroll-offset="25%">
            <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={team1}>With expertise spanning business strategy, product development, marketing, finance, and technology, we provide comprehensive support to businesses at all stages.</h2>
          </div>
        </div>
      </section>
      <section className="has-img-bg">
        <div className="container" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
          <h2 animchild="true" animin-type="slide-toLEFT" animin-delay="1">embrace<br/>courage</h2>
          <h2 animchild="true" animin-type="slide-toRIGHT" animin-delay="1">leap to<br/>success</h2>
        </div>
        <picture>
          <source srcSet={backgroundImage} type="image/avif"/>
          <img className="section-image" src={backgroundImageJpg} alt="Person being lifted in the air"/>
        </picture>
      </section>
      <section>
        <div className="container">
          <div className="anim-wrapper" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
            <p animchild="true" animin-type="small-slide-toTOP" animin-delay="1">
              Our team's rich cultural perspectives and global mindset enable us to adapt to the evolving needs of our clients, ensuring relevant and impactful solutions.
              <br />
              We pride ourselves on collaboration, working closely with entrepreneurs, startups, and corporate partners to co-create innovative solutions and unlock new opportunities.
            </p>
          </div>
          <div className="anim-wrapper" data-scroll data-scroll-speed="-1" data-scroll-repeat="false" data-scroll-offset="25%">
            <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={team2}>Dedicated to building long-lasting relationships, our team at Triggr Ventures continuously supports and mentors clients, striving to create a brighter future for businesses and communities worldwide.</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
          <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={team3}>What are you waiting for?<br /><a href="/contacts" className="inline-link">Say hi.</a></h2>
        </div>
      </section>
      {/* <section className="home-intro">
        <p>At Triggr Ventures, our diverse and talented team is the cornerstone of our success.</p>
        <h1>With expertise spanning business strategy, product development, marketing, finance, and technology, we provide comprehensive support to businesses at all stages.</h1>
      </section>
      <section className="home-intro" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '860px', height: '100vh' }}>
        <h1 style={{ color: '#FFF' }}>embrace courage</h1>
        <h1 style={{ color: '#FFF' }}>leap to success</h1>
      </section>
      <section className="home-intro" style={{ backgroundColor: 'rgb(0, 97, 167' }}>
        <p>Our team's rich cultural perspectives and global mindset enable us to adapt to the evolving needs of our clients, ensuring relevant and impactful solutions.<br></br>
          We pride ourselves on collaboration, working closely with entrepreneurs, startups, and corporate partners to co-create innovative solutions and unlock new opportunities.</p>
        <h1>Dedicated to building long-lasting relationships, our team at Triggr Ventures continuously supports and mentors clients, striving to create a brighter future for businesses and communities worldwide.</h1>
      </section>
      <section className="home-intro">
        <h1>What are you waiting? Say hi.</h1>
      </section> */}
    </>
  );
}

export default TeamPage