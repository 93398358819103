import React, { useRef, useEffect } from 'react';
import { splitText } from 'scripts/Utils';
import ogImage from 'render/assets/images/who_we_are_image.png';
import { Helmet } from 'react-helmet';

function WhoWeArePage() {
  useEffect(() => {
    [whoweare1, whoweare2, whoweare3].forEach((ref) => {
      splitText(ref.current);
    });
  }, []);

  const whoweare1 = useRef(null);
  const whoweare2 = useRef(null);
  const whoweare3 = useRef(null);

  return (
    <>
      <Helmet>
        <title>TriggrVentures - Who We Are</title>
        <meta name="description" content="Learn about TriggrVentures and what we do as an operator-led venture builder." />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <section>
        <div className="container" data-scroll data-scroll-call="initAnimate">
          <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 40.1 58.5" }} viewBox="0 0 40.1 58.5">
            <path d="M0 37.2h21.4c-8 4-13.1 12.2-13.2 21.1v.2h16.6v-.2c0-9.8 6.1-18.6 15.3-22.1v-14C30.9 18.8 24.8 10 24.8.2V0H8.2v.2c0 9 5.1 17.2 13.2 21.1H0v15.9z" />
          </svg>
          <span className="circle"></span>
          {/* <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 40.1 58.5" }} viewBox="0 0 40.1 58.5">
            <defs>
              <filter id="blur" x="-100%" y="-100%" width="300%" height="300%">
                  <feGaussianBlur stdDeviation="7" />
              </filter>
            </defs>
            <circle cx="20" cy="29.3" r="20"/>
          </svg> */}
        </div>
      </section>
      <section>
        <div className="container">
          <div className="anim-wrapper" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
            <p animchild="true" animin-type="small-slide-toTOP" animin-delay="1">
              Our team of industry professionals is dedicated to helping you transform your ideas into reality.
              <br /><br />
              With our extensive experience in building and growing businesses, we offer a comprehensive range of resources tailored to your needs.
            </p>
          </div>
          <div className="anim-wrapper" data-scroll data-scroll-speed="-1" data-scroll-repeat="false" data-scroll-offset="25%">
            <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={whoweare1}>Whether you're a startup, corporate, or an entrepreneur, we're here to provide the support necessary for your ideas to flourish and achieve their fullest potential.</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
          <h2 animchild="true" animin-type="text-word-reveal" ref={whoweare2}>Are these your challenges?</h2>
          <div className="challenges">
            <div className="challenge-item" animchild="true" animin-type="small-slide-toTOP" animin-delay="1"><h3>Idea<br />validation</h3><p>We help entrepreneurs assess the feasibility and potential of their business ideas, ensuring they pursue viable opportunities.</p></div>
            <div className="challenge-item" animchild="true" animin-type="small-slide-toTOP" animin-delay="2"><h3>Market<br />analysis</h3><p>We provide insights into market trends, customer needs, and competition, enabling businesses to develop effective strategies and make informed decisions.</p></div>
            <div className="challenge-item" animchild="true" animin-type="small-slide-toTOP" animin-delay="3"><h3>Product<br />development</h3><p>We assist in refining product ideas, creating prototypes, and streamlining the development process to ensure successful launches.</p></div>
            <div className="challenge-item" animchild="true" animin-type="small-slide-toTOP" animin-delay="4"><h3>Branding and<br />positioning</h3><p>We work with businesses to create a unique identity and effectively position their products or services in the market to stand out from competitors.</p></div>
            <div className="challenge-item" animchild="true" animin-type="small-slide-toTOP" animin-delay="5"><h3>Business<br />strategy</h3><p>We collaborate with clients to define clear objectives, develop actionable plans, and set achievable milestones for their businesses.</p></div>
            <div className="challenge-item" animchild="true" animin-type="small-slide-toTOP" animin-delay="6"><h3>Financial<br />planning</h3><p>We offer guidance on financial management, cash flow projections, and fundraising strategies to ensure our clients' businesses remain sustainable and profitable.</p></div>
            <div className="challenge-item" animchild="true" animin-type="small-slide-toTOP" animin-delay="7"><h3>Scaling and<br />growth</h3><p>We provide support and resources for businesses looking to expand, enter new markets, or grow their customer base.</p></div>
            <div className="challenge-item" animchild="true" animin-type="small-slide-toTOP" animin-delay="8"><h3>Talent acquisition and team building</h3><p>We help clients build strong teams by identifying the right talent, fostering a positive company culture, and developing effective leadership skills.</p></div>
          </div>
        </div>
      </section>
      <section>
        <div className="container" data-scroll data-scroll-repeat="false" data-scroll-offset="25%">
          <h2 animchild="true" animin-type="text-word-reveal" data-split="true" ref={whoweare3}>We want to help you,<br /><a href="/contacts" className="inline-link">let's get in touch</a></h2>
        </div>
      </section>
    </>
  );
}

export default WhoWeArePage