import React, { useEffect } from 'react';
import LogoBlack from 'render/assets/images/triggr-ventures-logo-black.svg';
import ArrowUp from 'render/assets/icons/triggr-ventures-arrow-up.svg';

function FooterComponent() {

  function scrollToSmoothly() {
    const currentPosition = window.pageYOffset;
    const targetPosition = 0;
    const distance = targetPosition - currentPosition;
    const duration = 500; // in milliseconds
    let start = null;
  
    function step(timestamp) {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      window.scrollTo(0, easeInOutQuad(progress, currentPosition, distance, duration));
      if (progress < duration) window.requestAnimationFrame(step);
    }
  
    window.requestAnimationFrame(step);
  }
  
  function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  return (
    <>
      <footer id="main-footer">
        <div className="container">
          <img className="logo" src={LogoBlack} alt="TriggrVentures Logo" width="auto" height="100%"/>
          <div>
            <p>Talk</p>
            <a href="mailto:info@triggrventures.com" className="inline-link underline-on-hover-only">info@triggrventures.com</a>
          </div>
          <div>
            <p>Read</p>
            <a href="/terms-and-conditions" className="inline-link underline-on-hover-only">Terms and conditions</a>
            <a href="/privacy-policy" className="inline-link underline-on-hover-only">Privacy policy</a>
          </div>
          <div>
            {/* <div>
              <a aria-label="View TriggrVentures Linkedin Page" target="_blank" rel="noopener" href="https://pt.linkedin.com/in/josealbertorocha" className="btn-s btn-ghost btn-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.35 28.35"><path d="M9.72 9.2c-.35.36-.79.53-1.3.53s-.95-.17-1.31-.53c-.35-.36-.53-.79-.53-1.31 0-.49.18-.92.53-1.28.38-.38.81-.56 1.31-.56s.93.19 1.31.56c.36.36.53.78.53 1.28 0 .52-.18.95-.54 1.31zm.45 2.02v9.58c0 .06-.04.15-.12.27-.12.08-.21.12-.27.12H7.05c-.06 0-.15-.04-.27-.12-.08-.12-.12-.21-.12-.27v-9.58c0-.06.04-.15.12-.27.12-.08.21-.12.27-.12h2.73c.06 0 .15.04.27.12.08.12.12.21.12.27zm7.77 2.58c-.22-.24-.57-.36-1.07-.36-.63 0-1.09.19-1.36.56-.26.38-.39.91-.39 1.6v5.19c0 .1-.04.19-.12.27-.08.08-.17.12-.27.12H12c-.06 0-.15-.04-.27-.12-.08-.12-.12-.21-.12-.27v-9.58c0-.06.04-.15.12-.27.12-.08.21-.12.27-.12h2.64c.08 0 .16.02.24.06.04.02.08.09.12.21v.15l.03.06v.24c.65-.63 1.55-.95 2.7-.95 1.29 0 2.27.31 2.97.92.71.65 1.07 1.59 1.07 2.82v6.47c0 .1-.04.19-.12.27-.08.08-.17.12-.27.12h-2.79c-.06 0-.15-.04-.27-.12-.08-.12-.12-.21-.12-.27v-5.84c.01-.5-.08-.89-.26-1.16z"/></svg></a>
              <a aria-label="View TriggrVentures Facebook Page" target="_blank" rel="noopener" href="#" className="btn-s btn-ghost btn-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.35 28.35"><path d="M12.57 21.77v-6.94h-2.34v-2.7h2.34v-1.99c0-1.13.31-2 .93-2.63s1.47-.93 2.54-.93 1.76.04 2.08.12v2.4H16.7c-.51 0-.87.11-1.05.33-.19.22-.28.54-.28.98v1.72h2.67l-.36 2.7h-2.31v6.94h-2.8z"/></svg></a>
            </div> */}
            <div>
              <a href="https://grafemadesign.com" target="_blank" rel="noreferrer" className="inline-link underline-on-hover-only">designed by grafema design</a>
              <a href="https://boop-collective.com" target="_blank" rel="noreferrer" className="inline-link underline-on-hover-only">developed by boop collective</a>
            </div>
          </div>
        </div>
      </footer>
      <button type="button" id="scroll-to-top" className="btn btn-ghost btn-square border-gray-lighter" onClick={scrollToSmoothly}>
        <img src={ArrowUp} alt="triggr arrow down" />
      </button>
    </>
  );
};

export default FooterComponent;