import React, { useRef, useEffect } from 'react';
import { splitText } from 'scripts/Utils';
import ogImage from 'render/assets/images/who_we_are_image.png';
import { Helmet } from 'react-helmet';

function ContactsPage() {

  useEffect(() => {
    [contacts1].forEach((ref) => {
      splitText(ref.current);
    });
  }, []);

  const contacts1 = useRef(null);

  return (
    <>
      <Helmet>
        <title>TriggrVentures - Contacts</title>
        <meta name="description" content="Contact TriggrVentures for any inquiries or to learn more about our services." />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <section data-scroll data-scroll-call="initAnimate">
        <div className="container">
          <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 40.1 58.5" }} viewBox="0 0 40.1 58.5">
            <path d="M0 37.2h21.4c-8 4-13.1 12.2-13.2 21.1v.2h16.6v-.2c0-9.8 6.1-18.6 15.3-22.1v-14C30.9 18.8 24.8 10 24.8.2V0H8.2v.2c0 9 5.1 17.2 13.2 21.1H0v15.9z" />
          </svg>
          <div>
            <h1 animchild="true" data-scroll-call="initAnimate" animin-type="text-word-reveal" data-split="true" ref={contacts1}>Let’s get<br />in touch!</h1>
            <p animchild="true" animin-type="slide-toLEFT">Email<br /><a className="inline-link underline-on-hover-only" href="mailto:info@triggrventures.com">info@triggrventures.com</a></p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 40.1 58.5" }} viewBox="0 0 40.1 58.5">
            <circle cx="20" cy="29.3" r="20" />
          </svg>
        </div>
      </section>
    </>
  );
}

export default ContactsPage