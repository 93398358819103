import React, { useEffect } from 'react';

import HeaderComponent from "./render/views/components/HeaderComponent";
import FooterComponent from "./render/views/components/FooterComponent";
import RoutesFile from './routes/routes';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      const animElements = document.querySelectorAll('[data-scroll-call="initAnimate"]');
      animElements.forEach((element) => {
        element.classList.add('init');
      });
    }, 250);

    function handleWindowResize() {
      if (!document.querySelector('html').classList.contains('touchevents')) {
        document.querySelector('body').classList.add('disable-transitions-on-resize');
        setTimeout(() => {
          document.querySelector('body').classList.remove('disable-transitions-on-resize');
        }, 100);
      }
    }

    // Add "is-inview" class to the initially visible elements after the page has loaded

    const scrollToTopBtn = document.querySelector('#scroll-to-top');
    const scrollOffset = 10;

    function handleScrollMove() {
      if (window.scrollY > scrollOffset) {
        scrollToTopBtn.classList.add('viewing');
      } else {
        scrollToTopBtn.classList.remove('viewing');
      }
    }

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('scroll', handleScrollMove);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, [location]);

  return (
    <>
      <main page={location.pathname}>
        <HeaderComponent />
        <RoutesFile />
        <FooterComponent />
      </main>
    </>
  )
}

export default App;
