import React from 'react';
import { Helmet } from 'react-helmet';
import ogImage from 'render/assets/images/who_we_are_image.png';

function PrivacyPolicyPage() {

  return (
    <>
      <Helmet>
        <title>TriggrVentures - Privacy Policy</title>
        <meta name="description" content="Read the privacy policy for TriggrVentures." />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <section>
        <div className="container">
          <h1>POLÍTICA DE PRIVACIDADE</h1>
          <p>A privacidade dos usuários do nosso site é importante para nós. Esta política de privacidade descreve como coletamos, usamos e protegemos as informações pessoais dos usuários. Ao utilizar este site, você concorda com a coleta e uso de suas informações pessoais conforme descrito nesta política de privacidade.</p>

          <div>
            <h3><b>Informações coletadas</b></h3>
            <p>Podemos coletar informações pessoais, como nome, endereço de e-mail, endereço residencial, número de telefone e informações de pagamento, quando fornecidas voluntariamente pelos usuários do site. Além disso, podemos coletar informações de uso do site, incluindo informações sobre o navegador, sistema operacional, endereço IP, data e hora de acesso e páginas visitadas.</p>
          </div>

          <div>
            <h3><b>Uso das informações coletadas</b></h3>
            <p>Podemos utilizar as informações pessoais coletadas para fins de comunicação com os usuários, como responder a dúvidas ou fornecer informações sobre produtos e serviços. Também podemos utilizar as informações pessoais para fins de marketing, como enviar newsletters ou promoções. As informações de uso do site podem ser utilizadas para melhorar o site e personalizar a experiência do usuário.</p>
          </div>

          <div>
            <h3><b>Compartilhamento de informações</b></h3>
            <p>Não compartilhamos informações pessoais com terceiros, exceto quando necessário para fornecer um produto ou serviço solicitado pelo usuário. Também podemos compartilhar informações pessoais em caso de solicitação de autoridades legais ou em caso de violação destes termos e condições.</p>
          </div>

          <div>
            <h3><b>Proteção das informações</b></h3>
            <p>Tomamos medidas de segurança para proteger as informações pessoais dos usuários, incluindo o uso de criptografia e medidas de segurança física. No entanto, não podemos garantir a segurança absoluta das informações transmitidas pela Internet.</p>
          </div>

          <div>
            <h3><b>Cookies</b></h3>
            <p>Podemos utilizar cookies para personalizar a experiência do usuário e fornecer informações sobre o uso do site. Os usuários podem optar por desativar os cookies nas configurações do navegador.</p>
          </div>

          <div>
            <h3><b>Alterações nesta política de privacidade</b></h3>
            <p>Podemos alterar esta política de privacidade a qualquer momento, sem aviso prévio. A utilização do site após a publicação de tais alterações constitui aceitação da política de privacidade atualizada.</p>
          </div>

          <div>
            <h3><b>Contato</b></h3>
            <p>Se você tiver alguma dúvida ou comentário sobre esta política de privacidade, entre em contato conosco.</p>
          </div>

          <div>
            <p>Ao utilizar este site, você concorda com a coleta e uso de suas informações pessoais conforme descrito nesta política de privacidade.</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicyPage