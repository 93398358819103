import React from 'react';
import { Helmet } from 'react-helmet';
import ogImage from 'render/assets/images/who_we_are_image.png';

function TermsAndConditionsPage() {

  return (
    <>
      <Helmet>
        <title>TriggrVentures - Terms and Conditions</title>
        <meta name="description" content="Read the terms and conditions for TriggrVentures." />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <section data-scroll>
        <div className="container">
          <h1>TERMOS E CONDIÇÕES</h1>
          <p>Bem-vindo ao nosso site. Ao acessar e utilizar o nosso site, você concorda em cumprir estes termos e condições de uso. Se você não concorda com estes termos e condições, não utilize o nosso site.</p>

          <div>
            <h3><b>Propriedade do site</b></h3>
            <p>Este site é de propriedade exclusiva da TriggrVentures, e todo o conteúdo apresentado no site, incluindo imagens, texto, gráficos, logotipos e ícones, são de propriedade da TriggrVentures ou de seus fornecedores e parceiros.</p>
          </div>
          <div>
            <h3><b>Uso do site</b></h3>
            <p>Ao utilizar este site, você concorda em não copiar, reproduzir, distribuir, transmitir, exibir, publicar, vender, licenciar, modificar ou criar trabalhos derivados de qualquer parte do site, a menos que autorizado pela TriggrVentures. Você também concorda em não utilizar o site para fins ilegais ou proibidos.</p>
          </div>
          <div>
            <h3><b>Conteúdo gerado pelo usuário</b></h3>
            <p>Ao postar ou enviar conteúdo para o nosso site, você concorda em não enviar material que seja ilegal, difamatório, abusivo, ameaçador, ofensivo, prejudicial, obsceno, pornográfico, profano ou que viole a propriedade intelectual de terceiros. A TriggrVentures se reserva o direito de remover qualquer conteúdo que viole estes termos e condições.</p>
          </div>
          <div>
            <h3><b>Propriedade intelectual</b></h3>
            <p>Todo o conteúdo deste site é protegido por leis de propriedade intelectual, incluindo marcas registradas, direitos autorais e patentes. Qualquer uso não autorizado de qualquer parte do site pode violar as leis de propriedade intelectual.</p>
          </div>
          <div>
            <h3><b>Isenções de responsabilidade</b></h3>
            <p>Este site é fornecido "como está", sem garantias de qualquer tipo, expressas ou implícitas. A TriggrVentures não garante que o site seja livre de erros ou interrupções ou que o conteúdo seja preciso, confiável ou atualizado. A TriggrVentures não será responsável por quaisquer danos, diretos ou indiretos, decorrentes do uso ou incapacidade de uso do site.</p>
          </div>
          <div>
            <h3><b>Rescisão</b></h3>
            <p>A TriggrVentures pode rescindir o acesso de um usuário ao site a qualquer momento, sem aviso prévio, se o usuário violar estes termos e condições.</p>
          </div>
          <div>
            <b></b>
            <h3><b>Lei aplicável</b></h3>
            <p>Estes termos e condições serão regidos e interpretados de acordo com as leis de Portugal. Qualquer disputa decorrente ou relacionada a estes termos e condições será submetida à jurisdição exclusiva dos tribunais de Portugal.</p>
          </div>
          <div>
            <h3><b>Alterações aos termos e condições</b></h3>
            <p>A TriggrVentures se reserva o direito de alterar estes termos e condições a qualquer momento, sem aviso prévio. O uso continuado do site após a publicação de tais alterações constitui aceitação dos termos e condições atualizados.</p>
          </div>
          <div>
            <p>Ao utilizar este site, você concorda em cumprir estes termos e condições. Se você tiver alguma dúvida ou comentário sobre estes termos e condições, entre em contato conosco.</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsAndConditionsPage